import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from "docz";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "aws-buckets"
    }}>{`AWS Buckets`}</h1>
    <h2 {...{
      "id": "web-assets"
    }}>{`Web Assets`}</h2>
    <p>{`The primary use case for this bucket will be to store CMS assets. Therefore it will be a public bucket. Do not use it to store secrets!`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://s3.console.aws.amazon.com/s3/home?region=eu-central-1"
        }}>{`Create a new bucket`}</a>{`:`}</li>
    </ol>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Note`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`webassets`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`eu-central-1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Select any of the us regions for US based clients`}</td>
        </tr>
      </tbody>
    </table>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Enable ACLs under the `}<inlineCode parentName="li">{`Object Ownership`}</inlineCode>{` section`}</li>
    </ol>
    <p><img alt="Step 3" src={require("./AWS/aws-s3-bucket-3.png")} /></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Disable `}<inlineCode parentName="p">{`Block all public access`}</inlineCode>{`.`}<br />{`Since this is for public assets anyway it is okay to allow public access.
`}<img alt="Step 2" src={require("./AWS/aws-s3-bucket-2.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Save the new bucket.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to the bucket's `}<inlineCode parentName="p">{`Permissions`}</inlineCode>{` tab, scroll down and edit the `}<inlineCode parentName="p">{`Bucket policy`}</inlineCode>{` to allow public read access to all objects:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Sid": "PublicRead",
      "Effect": "Allow",
      "Principal": "*",
      "Action": ["s3:GetObject", "s3:GetObjectVersion"],
      "Resource": ["arn:aws:s3:::YOUR_BUCKET_NAME/*"]
    }
  ]
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "database-snapshots"
    }}>{`Database Snapshots`}</h2>
    <p>{`The primary use case for this bucket will be to store database backups.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://s3.console.aws.amazon.com/s3/home?region=eu-central-1"
        }}>{`Create a new bucket`}</a>{`:`}</li>
    </ol>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Note`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`db-snapshots`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`eu-central-1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Select any of the us regions for US based clients`}</td>
        </tr>
      </tbody>
    </table>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><strong parentName="li">{`Important`}</strong>{` Keep public access disabled.`}</li>
      <li parentName="ol">{`Save the new bucket.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      